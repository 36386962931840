import client from '@/services/';
import { saveAs } from 'file-saver';


const state = () => ({
    all: [],
    one: {},
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, regionId: '', startDate: '', endDate: '', communeId: '', formeJuridiqueId: '', /*produitId: '',*/ raisonSociale: ''},) {
        let { data } = payload.texte !== '' ? await client.get('requete/membre/'+payload.page, {params: {texte: payload.texte}}) : await client.get('requete/membre/'+payload.page+'/'+payload.size+'/true' , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, communeId: payload.communeId, formeJuridiqueId: payload.formeJuridiqueId/*, produitId: payload.produitId*/}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllDirigeant(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', formeJuridiqueId: '', sexe: '', }) {
        let { data } = await client.get('/requete/entreprises/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, sexe: payload.sexe, formeJuridiqueId: payload.formeJuridiqueId}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllSecteur(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', secteurActivite: '' }) {
        let { data } = await client.get('/requete/entreprises/secteurActivite/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, secteurActivite: payload.secteurActivite}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllSecteurPlus(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', secteurId: '', sessionId: '', divisionId: '', groupeId: '', classeId: '', sousClasseId: '', produitId: '', sexe: '', formeJuridiqueId: ''}) {
        let { data } = await client.get('/requete/entreprises/secteurActivite/second/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, secteurId: payload.secteurId, sessionId: payload.sessionId, divisionId: payload.divisionId, groupeId: payload.groupeId, classeId: payload.classeId, sousClasseId: payload.sousClasseId, produitId: payload.produitId, sexe: payload.sexe, formeJuridiqueId: payload.formeJuridiqueId}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllCommune(cxt, payload = {page: 1, size: 7, startDate: '', endDate: '', communeId: ''}) {
        let { data } = await client.get('/requete/entreprises/commune/'+payload.page+'/'+payload.size , {params: {startDate: payload.startDate, endDate: payload.endDate, communeId: payload.communeId,}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllAncienne(cxt, payload = {page: 1, size: 7, startDate: '', endDate: '', regionId: '', secteurId: '', categorieId: '', brancheId: '', activiteId: ''}) {
        let { data } = await client.get('/requete/entreprises/secteurActivite/ahilido/'+payload.page+'/'+payload.size , {params: {startDate: payload.startDate, endDate: payload.endDate, regionId: payload.regionId, secteurId: payload.secteurId, categorieId: payload.categorieId, brancheId: payload.brancheId, activiteId: payload.activiteId,}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async exportToExcel(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', formeJuridiqueId: '', sexe: '', }) {
        try {
            let { data } = await client.get('/requete/export/entreprises/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, sexe: payload.sexe, formeJuridiqueId: payload.formeJuridiqueId}, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DirigeantParSexe.xlsx'); // Nom du fichier à télécharger
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },
    async exportToExcelActivite(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', secteurActivite: '' }) {
        try {
            let { data } = await client.get('/requete/export/entreprises/secteurActivite/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, secteurActivite: payload.secteurActivite}, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DetailsActivite.xlsx'); // Nom du fichier à télécharger
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },
    async exportToExcelNomNap(cxt, payload = {page: 1, size: 7, regionId: '', startDate: '', endDate: '', secteurId: '', sessionId: '', divisionId: '', groupeId: '', classeId: '', sousClasseId: '', produitId: '', sexe: '', formeJuridiqueId: ''}) {
        try {
            let { data } = await client.get('/requete/export/entreprises/secteurActivite/second/'+payload.page+'/'+payload.size , {params: {regionId: payload.regionId, startDate: payload.startDate, endDate: payload.endDate, secteurId: payload.secteurId, sessionId: payload.sessionId, divisionId: payload.divisionId, groupeId: payload.groupeId, classeId: payload.classeId, sousClasseId: payload.sousClasseId, produitId: payload.produitId, sexe: payload.sexe, formeJuridiqueId: payload.formeJuridiqueId}, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'NomenclatureNap.xlsx'); 
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },
    async exportToExcelAncienne(cxt, payload = {page: 1, size: 7, startDate: '', endDate: '', regionId: '', secteurId: '', categorieId: '', brancheId: '', activiteId: ''}) {
        try {
            let { data } = await client.get('/requete/export/entreprises/secteurActivite/ahilido/'+payload.page+'/'+payload.size , {params: {startDate: payload.startDate, endDate: payload.endDate, regionId: payload.regionId, secteurId: payload.secteurId, categorieId: payload.categorieId, brancheId: payload.brancheId, activiteId: payload.activiteId,}, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'NomenclatureAncienne.xlsx'); 
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },
    async exportToExcelCommune(cxt, payload = {page: 1, size: 7, startDate: '', endDate: '', communeId: ''}) {
        try {
            let { data } = await client.get('/requete/export/entreprises/commune/'+payload.page+'/'+payload.size , {params: {startDate: payload.startDate, endDate: payload.endDate, communeId: payload.communeId,}, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'EntrepriseCommune.xlsx'); 
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};